import React, { useState, useEffect } from 'react'

import { Link } from 'react-router-dom'
import { FaSearch, FaRegUser, FaRegHeart, FaCartArrowDown, FaTrash, FaAngleDown, FaTimes } from "react-icons/fa"
import { CgMenuLeft, IconName } from "react-icons/cg";
import { GiBigDiamondRing, GiDiamondTrophy, GiCutDiamond, GiTopaz } from "react-icons/gi";

import { Typography, Box, List, ListItem, ListItemButton, ListItemText, ListItemIcon, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
// import Accordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import Typography from '@mui/material/Typography';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


function MobileNav() {
    let[navstate, setNavstate] = useState("false")
    const navOpen = () => {
        setNavstate(!navstate);
    };
  return (
    <>
        <div className="off-canvas-item">
            <ul className="accordion list-style-none" id="mobNav-menu">
                <li className="accordion-item">
                    <div className="accordion-header" id="engagementRingOne">
                        <div className="accordion-button d-flex justify-content-between align-items-center">
                            <Link  className='mobile-parent' to="/our-engagement-rings">Engagement rings</Link>
                            <button className="action-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#engagementOne" aria-expanded="true" aria-controls="engagementOne"></button>
                        </div>
                    </div>
                    <div id="engagementOne" className="accordion-collapse collapse" aria-labelledby="engagementRingOne" data-bs-parent="#mobNav-menu">
                        <div className="accordion-body">
                            <Box sx={{px: 2.2, py: 2}}>
                                <div className="row ">
                                    <div className="col-md-12 col-lg-12">
                                        <div className="row">
                                            <div className="lvl-1 col-12">
                                                <div>
                                                    <a href="#" className="site-nav lvl-1 menu-title">Design Your Engagement Ring</a>
                                                    <ul className="subLinks">
                                                        <li className="lvl-2" style={{"--iconImg":"url('/assets/img/icon/spirit/gem.png')"}}><Link to='/settings-diamond/all' className="site-nav lvl-2" onClick={(e)=>localStorage.setItem("ChooseEng","1")} >Start with Diamond</Link></li>
                                                        <li className="lvl-2" style={{"--iconImg":"url('/assets/img/icon/spirit/gem.png')"}}><Link onClick={(e)=>localStorage.setItem("ChooseEng","2")} to='/settings-ring/all' className="site-nav lvl-2">Start with Setting</Link></li>
                                                        <li className="lvl-2" style={{"--iconImg":"url('/assets/img/icon/spirit/gem.png')"}}><Link to='/settings-gemstone' className="site-nav lvl-2">Start with a Gemstone</Link></li>

                                                    </ul>
                                                </div>
                                                <div className='mt-3'>
                                                    <a href="#" className="site-nav lvl-1 menu-title">Shop by Metal</a>
                                                    <ul className="subLinks doubleCol">
                                                        <li className="lvl-2" style={{"--iconImg":"url('/assets/img/icon/spirit/metal_rg_dropdown.png')"}}><Link to='/settings-ring/metal/18K Rose Gold' className="site-nav lvl-2">Rose Gold </Link></li>
                                                        <li className="lvl-2" style={{"--iconImg":"url('/assets/img/icon/spirit/metal_wg_dropdown.png')"}}><Link to='/settings-ring/metal/18K White Gold' className="site-nav lvl-2">White Gold</Link></li>
                                                        <li className="lvl-2" style={{"--iconImg":"url('/assets/img/icon/spirit/metal_yg_dropdown.png')"}}><Link to='/settings-ring/metal/18K Yellow Gold' className="site-nav lvl-2">Yellow Gold</Link></li>
                                                        <li className="lvl-2" style={{"--iconImg":"url('/assets/img/icon/spirit/metal_pt_dropdown.png')"}}><Link to='/settings-ring/metal/Platinum' className="site-nav lvl-2">Platinum</Link></li>
                                                    </ul>
                                                </div>

                                            </div>
                                            <div className="lvl-1 col-12">
                                                <div>
                                                    <a href="#" className="site-nav lvl-1 menu-title">Shop by Shape</a>
                                                    <ul className="subLinks doubleCol">
                                                        <li className="lvl-2" style={{"--iconImg":"url('/assets/img/icon/spirit/round.png')"}}><Link to='/settings-diamond/shape/round' className="site-nav lvl-2">Round</Link></li>
                                                        <li className="lvl-2" style={{"--iconImg":"url('/assets/img/icon/spirit/princess.png')"}}><Link to='/settings-diamond/shape/princess' className="site-nav lvl-2">Princess</Link></li>
                                                        <li className="lvl-2" style={{"--iconImg":"url('/assets/img/icon/spirit/cushion.png')"}}><Link to='/settings-diamond/shape/cushion' className="site-nav lvl-2">Cushion</Link></li>

                                                        <li className="lvl-2" style={{"--iconImg":"url('/assets/img/icon/spirit/emerald.png')"}}><Link to='/settings-diamond/shape/emerald' className="site-nav lvl-2">Emerald</Link></li>
                                                        <li className="lvl-2" style={{"--iconImg":"url('/assets/img/icon/spirit/marquise.png')"}}><Link to='/settings-diamond/shape/marquise' className="site-nav lvl-2">Marquise</Link></li>

                                                        <li className="lvl-2" style={{"--iconImg":"url('/assets/img/icon/spirit/pear.png')"}}><Link to='/settings-diamond/shape/pear' className="site-nav lvl-2">Pear</Link></li>
                                                        <li className="lvl-2" style={{"--iconImg":"url('/assets/img/icon/spirit/oval.png')"}}><Link to='/settings-diamond/shape/oval' className="site-nav lvl-2">Oval</Link></li>
                                                        <li className="lvl-2" style={{"--iconImg":"url('/assets/img/icon/spirit/rediant.png')"}}><Link to='/settings-diamond/shape/heart' className="site-nav lvl-2">Radiant</Link></li>

                                                        <li className="lvl-2" style={{"--iconImg":"url('/assets/img/icon/spirit/asscher.png')"}}><Link to='/settings-diamond/shape/asscher' className="site-nav lvl-2">Asscher</Link></li>
                                                        <li className="lvl-2" style={{"--iconImg":"url('/assets/img/icon/spirit/heart.png')"}}><Link to='/settings-diamond/shape/heart' className="site-nav lvl-2">Heart</Link></li>
                                                    </ul>
                                                </div>

                                            </div>

                                            <div className="lvl-1 col-12">

                                                <div className=''>
                                                    <a href="#" className="site-nav lvl-1 menu-title">Shop by Setting Styles</a>
                                                    <ul className="subLinks doubleCol">
                                                        <li className="lvl-2" style={{"--iconImg":"url('/assets/img/icon/spirit/engagement_ring_icon_solitaire.png')"}}><Link to='/settings-ring/style/Solitaire' className="site-nav lvl-2">Solitaire</Link></li>
                                                        <li className="lvl-2" style={{"--iconImg":"url('/assets/img/icon/spirit/engagement_ring_icon_pave.png')"}}><Link to='/settings-ring/style/Pave' className="site-nav lvl-2">Pave</Link></li>

                                                        <li className="lvl-2" style={{"--iconImg":"url('/assets/img/icon/spirit/engagement_ring_icon_channel_set.png')"}}><Link to='/settings-ring/style/Channel-Set' className="site-nav lvl-2">Channel-Set</Link></li>
                                                        <li className="lvl-2" style={{"--iconImg":"url('/assets/img/icon/spirit/engagement_ring_icon_sidestones.png')"}}><Link to='/settings-ring/style/Side-Stones' className="site-nav lvl-2">Side-Stones</Link></li>

                                                        <li className="lvl-2" style={{"--iconImg":"url('/assets/img/icon/spirit/engagement_ring_icon_tension.png')"}}><Link to='/settings-ring/style/Tension' className="site-nav lvl-2">Tension</Link></li>
                                                        <li className="lvl-2" style={{"--iconImg":"url('/assets/img/icon/spirit/engagement_ring_icon_vintage.png')"}}><Link to='/settings-ring/style/Vintage' className="site-nav lvl-2">Vintage</Link></li>
                                                        <li className="lvl-2" style={{"--iconImg":"url('/assets/img/icon/spirit/engagement_ring_icon_halo.png')"}}><Link to='/settings-ring/style/Halo' className="site-nav lvl-2">Halo</Link></li>
                                                        <li className="lvl-2" style={{"--iconImg":"url('/assets/img/icon/spirit/engagement_ring_icon_three_stones.png')"}}><Link to='/settings-ring/style/Three-Stone' className="site-nav lvl-2">Three Stone</Link></li>
                                                    </ul>
                                                </div> 

                                            </div>

                                        </div>
                                    </div>
                                    
                                </div>
                            </Box>
                            
                            <img className='img-fluid w-100' src="/assets/img/cate-1.jpg" alt="" style={{height: 8 + 'rem', objectFit: 'cover'}} />
                        </div>
                    </div>
                </li>
                <li className="accordion-item">
                    <div className="accordion-header" id="widdingRingOne">
                        <div className="accordion-button d-flex justify-content-between align-items-center">
                            <Link  className='mobile-parent' to="/our-wedding-bands">Wedding rings</Link>
                            <button className="action-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#weddingOne" aria-expanded="false" aria-controls="weddingOne"></button>
                        </div>
                    </div>
                    <div id="weddingOne" className="accordion-collapse collapse" aria-labelledby="widdingRingOne" data-bs-parent="#mobNav-menu">
                        <div className="accordion-body">
                            <Box sx={{px: 2.2, py: 2}}>
                                <div className="row">
                                    <div className="col-md-12 col-lg-12">
                                        <div className="row">
                                            <div className="lvl-1 col-12">
                                                <div>
                                                    <a href="#" className="site-nav lvl-1 menu-title">Shop For Women</a>
                                                    <ul className="subLinks">
                                                        <li className="lvl-2" style={{"--iconImg":"url('/assets/img/icon/spirit/wedding_band_icon_women_classic.png')"}}><Link to='/wedding-bands/female/Classic' className="site-nav lvl-2">Classic Wedding Bands</Link></li>

                                                        <li className="lvl-2" style={{"--iconImg":"url('/assets/img/icon/spirit/menu_header_icon_women_eternity_band.png')"}}><Link to='/wedding-bands/female/Eternity' className="site-nav lvl-2">Eternity Bands</Link></li>
                                                        <li className="lvl-2" style={{"--iconImg":"url('/assets/img/icon/spirit/menu_header_icon_women_diamond_band.png')"}}><Link to='/wedding-bands/female/Diamond' className="site-nav lvl-2">Diamond Bands</Link></li>
                                                    </ul>
                                                </div>

                                            </div>

                                            <div className="lvl-1 col-12">
                                                <div className=''>
                                                    <a href="#" className="site-nav lvl-1 menu-title">Shop For Men</a>
                                                    <ul className="subLinks">
                                                        <li className="lvl-2" style={{"--iconImg":"url('/assets/img/icon/spirit/wedding_band_icon_men_classic.png')"}}><Link to='/wedding-bands/male/Classic' className="site-nav lvl-2">Classic Wedding Bands</Link></li>
                                                        <li className="lvl-2" style={{"--iconImg":"url('/assets/img/icon/spirit/wedding_band_icon_men_s_diamond.png')"}}><Link to='/wedding-bands/male/Diamond' className="site-nav lvl-2">Diamond Bands</Link></li>
                                                    </ul>
                                                </div>

                                            </div>
                                            <div className="lvl-1 col-12">
                                                <div>
                                                    <a href="#" className="site-nav lvl-1 menu-title">SHOP BY METAL</a>
                                                    <ul className="subLinks">
                                                        <li className="lvl-2" style={{"--iconImg":"url('/assets/img/icon/spirit/metal_rg_dropdown.png')"}}><Link to='/wedding-bands/metal/18K Rose Gold' className="site-nav lvl-2">Rose Gold </Link></li>
                                                        <li className="lvl-2" style={{"--iconImg":"url('/assets/img/icon/spirit/metal_wg_dropdown.png')"}}><Link to='/wedding-bands/metal/18K White Gold' className="site-nav lvl-2">White Gold</Link></li>
                                                        <li className="lvl-2" style={{"--iconImg":"url('/assets/img/icon/spirit/metal_yg_dropdown.png')"}}><Link to='/wedding-bands/metal/18K Yellow Gold' className="site-nav lvl-2">Yellow Gold</Link></li>
                                                        <li className="lvl-2" style={{"--iconImg":"url('/assets/img/icon/spirit/metal_pt_dropdown.png')"}}><Link to='/wedding-bands/metal/Platinum' className="site-nav lvl-2">Platinum</Link></li>
                                                    </ul>
                                                </div> 

                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </Box>
                        </div>
                    </div>
                </li>
                <li className="accordion-item">
                    <div className="accordion-header" id="DiamondringThree">
                        <div className="accordion-button d-flex justify-content-between align-items-center">
                            <Link  className='mobile-parent' to="/our-fine-jewelry">Fine Jewellery</Link>
                            <button className="action-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#DiamondThree" aria-expanded="false" aria-controls="DiamondThree"></button>
                        </div>
                    </div>
                    <div id="DiamondThree" className="accordion-collapse collapse" aria-labelledby="DiamondringThree" data-bs-parent="#mobNav-menu">
                        <div className="accordion-body">
                            <Box sx={{px: 2.2, py: 2}}>
                                <div className="row">
                                    <div className="col-md-12 col-lg-12">
                                        <div className="row">
                                            <div className="lvl-1 col-12">
                                                <div>
                                                    <a href="#" className="site-nav lvl-1 menu-title">Jewellery Type</a>
                                                    <ul className="subLinks">
                                                        <li className="lvl-2" style={{"--iconImg":"url('/assets/img/icon/spirit/menu_header_earrings_drop.png')"}}><Link to='/fine-jewellery/type/Earrings' className="site-nav lvl-2">Earrings</Link></li>
                                                        <li className="lvl-2" style={{"--iconImg":"url('/assets/img/icon/spirit/menu_header_necklace.png')"}}><Link to='/fine-jewellery/type/Necklaces' className="site-nav lvl-2">Necklaces</Link></li>
                                                        <li className="lvl-2" style={{"--iconImg":"url('/assets/img/icon/spirit/menu_header_bracelet_1.png')"}}><Link to='/fine-jewellery/type/Bracelets' className="site-nav lvl-2">Bracelets</Link></li>
                                                        <li className="lvl-2" style={{"--iconImg":"url('/assets/img/icon/spirit/menu_header_icon_women_diamond_band.png')"}}><Link to='/fine-jewellery/type/Rings' className="site-nav lvl-2">Rings</Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="lvl-1 col-12">
                                                <div>
                                                    <a href="#" className="site-nav lvl-1 menu-title">Shop By Metal</a>
                                                    <ul className="subLinks">
                                                        <li className="lvl-2" style={{"--iconImg":"url('/assets/img/icon/spirit/metal_rg_dropdown.png')"}}><Link to='/fine-jewellery/metal/18K Rose Gold' className="site-nav lvl-2">Rose Gold </Link></li>
                                                        <li className="lvl-2" style={{"--iconImg":"url('/assets/img/icon/spirit/metal_wg_dropdown.png')"}}><Link to='/fine-jewellery/metal/18K White Gold' className="site-nav lvl-2">White Gold</Link></li>
                                                        <li className="lvl-2" style={{"--iconImg":"url('/assets/img/icon/spirit/metal_yg_dropdown.png')"}}><Link to='/fine-jewellery/metal/18K Yellow Gold' className="site-nav lvl-2">Yellow Gold</Link></li>
                                                        <li className="lvl-2" style={{"--iconImg":"url('/assets/img/icon/spirit/metal_pt_dropdown.png')"}}><Link to='/fine-jewellery/metal/Platinum' className="site-nav lvl-2">Platinum</Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>

                                </div>
                            </Box>
                        </div>
                    </div>
                </li>
                

                <li className="accordion-item">
                    <div className="accordion-header" id="headingeght">
                        <div className="accordion-button d-flex justify-content-between align-items-center">
                            <Link  className='mobile-parent' to="/custom-design">Customize</Link>
                            {/* <button className="action-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseeght" aria-expanded="false" aria-controls="collapseeght"></button> */}
                        </div>
                    </div>
                </li>
                <li className="accordion-item">
                    <div className="accordion-header" id="headingeght">
                        <div className="accordion-button d-flex justify-content-between align-items-center">
                            <Link  className='mobile-parent' to="/learn">Learn</Link>
                            {/* <button className="action-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseeght" aria-expanded="false" aria-controls="collapseeght"></button> */}
                        </div>
                    </div>
                </li>

                <li className="accordion-item">
                    <div className="accordion-header" id="headingeght">
                        <div className="accordion-button d-flex justify-content-between align-items-center">
                            <Link className='mobile-parent' to="/faq">FAQ</Link>
                            {/* <button className="action-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseeght" aria-expanded="false" aria-controls="collapseeght"></button> */}
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </>
  )
}

export default MobileNav