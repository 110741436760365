import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

import InstagramFeed  from 'react-ig-feed'
import 'react-ig-feed/dist/index.css'

import 'owl.carousel/dist/assets/owl.carousel.css'
import OwlCarousel from 'owl.carousel'
import {AnimatedOnScroll} from "react-animated-css-onscroll";
import Button from 'react-bootstrap/Button';


import {Controller, Scene} from 'react-scrollmagic';
import {Tween} from 'react-gsap';
function Home() {

    let View3d = {
        //height: '60vh',
        height: '65vh',
        //height: '430px'
    }

    React.useEffect(()=>{
        var script = document.createElement('script');
        script.src = "assets/js/init.js";
        
        document.head.appendChild(script);
    }, [])


    const ring_collections = [
        {
            "name" : "HALO",
            "desc" : "Flora and Fauna",
            "image" : "10035WG18K-Halo-Fomalhaut.png",
            "href" : "/settings-ring/style/Halo",
        }, {
            "name" : "SIDE-STONE",
            "desc" : "Rose Gold Combination",
            "image" : "10023RG18K-SideStone-Melpe.png",
            "href" : "/settings-ring/style/Side-Stones",
        }, {
            "name" : "SOLITAIRE",
            "desc" : "Simple and Radiantly Gold",
            "image" : "10022YG18K-Solitaire-Altair.png",
            "href" : "/settings-ring/style/Solitaire",
        }, {
            "name" : "THREE-STONE",
            "desc" : "Twisted Stones",
            "image" : "10029WG18K-ThreeStone-Kitalpha.png",
            "href" : "/settings-ring/style/Three-Stone",
        },{
            "name" : "PAVE",
            "desc" : "Round the World Shiny",
            "image" : "10031WG18K-Pave-Stella.png",
            "href" : "/settings-ring/style/Pave",
        },{
            "name" : "PAVE",
            "desc" : "Rosy and Shiny",
            "image" : "10040RG18K-Pave-Ascellaete.png",
            "href" : "/settings-ring/style/Pave",
        },{
            "name" : "CHANNEL SET",
            "desc" : "Discretely Grand",
            "image" : "10038WG18K-Channel-Set-Mimoria-1.png",
            "href" : "/settings-ring/style/Channel-Set",
        },{
            "name" : "SOLITAIRE",
            "desc" : "Elegantly Simple",
            "image" : "10020WG18K-Solitaire-Sirius.png",
            "href" : "/settings-ring/style/Solitaire",
        },
    ];

    const wedingbands_collections = [
        {
            "name" : "Women's Eternity",
            "desc" : "",
            "image" : "20006WG18K-Eternity-Full-Ballad.png",
            "href" : "/wedding-bands/female/Eternity",
        },{
            "name" : "Women's Classic",
            "desc" : "",
            "image" : "20002WG18K-Classic-Petite-Comfort-Fit-2mm.png",
            "href" : "/wedding-bands/female/Classic",
        },{
            "name" : "Women's Diamond",
            "desc" : "",
            "image" : "20009WG18K-Diamond-Flair.png",
            "href" : "/wedding-bands/female/Diamond",
        },{
            "name" : "Men's Classic",
            "desc" : "",
            "image" : "30002WG18K-Classic-Comfort-Fit-5mm.png",
            "href" : "/wedding-bands/male/Classic",
        },{
            "name" : "Men's Diamond",
            "desc" : "",
            "image" : "30017WG18K-Classic-Bennett-2.png",
            "href" : "/wedding-bands/male/Diamond",
        },{
            "name" : "Men's Carved",
            "desc" : "",
            "image" : "30033WG18K-Carved-Hendrix.png",
            "href" : "/wedding-bands/male/Carved",
        },
    ];

    return (
      <>
          <Helmet>
              <title>Baemont – Think Diamond, Think BAEMONT! | Home</title>
          </Helmet>
        <div id="home" className="hero-slider owl-carousel owl-theme">
            <div className="single-hs-item item-bg1">
                <div className="d-table w-100">
                    <div className="d-tablecell">
                        <div className="hero-text">
                            <h1>Its Time to Create <br/>Your Perfection</h1>
                        
                            <div className="slider-btn mt-5">
                            <Link to="/settings-ring/all" className="btn btn-default-dark">Start With a Setting</Link>
                            <Link to="/settings-diamond/all" className="btn btn-default-dark">Start With a Diamond</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="single-hs-item item-bg2">
                <div className="d-table w-100">
                    <div className="d-tablecell">
                        <div className="hero-text  mx-auto text-center">
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <img className="img-fluid slider-slice" width="400" src="/assets/img/slider/customize_cta.png"/>
                        
                            <div className="slider-btn text-center">
                                <Link  to="/custom-design"   className="mt-5 btn btn-default-dark">Let’s Talk</Link>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="single-hs-item item-bg3">
                <div className="d-table w-100">
                    <div className="d-tablecell">
                        <div className="hero-text  mx-auto text-center">
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <img className="img-fluid slider-slice" width="400" src="/assets/img/slider/singapore_cta.png"/>

                            <div className="slider-btn   text-center">
                            
                            <a  href="https://calendly.com/baemont/no18capitolsingapore" target="_blank" className="mt-5 btn btn-default-dark">Book Now</a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="container-fluid">
            <div className="row g-5 m-0 my-5">
                <div className="home-card-outter   col-sm-6 col-md-6 col-lg-3">
                    <a className="home-card overflow-hidden card text-white p-0" href='/our-engagement-rings'>
                        <img src="assets/img/cat-img/engagement-ring.png" className="img-fluid" alt="..." />
                        <div className="card-img-overlay btn-text">
                            <div className="btn cate-btn">ENGAGEMENT RINGS</div>
                        </div>
                    </a>
                </div>
                <div className="home-card-outter    col-sm-6 col-md-6 col-lg-3">
                    <a className="home-card  overflow-hidden  card text-white p-0" href='/our-wedding-bands'>
                        <img src="assets/img/cat-img/wedding-band.png" className="img-fluid" alt="..." />
                        <div className="card-img-overlay btn-text">
                            <div className="btn cate-btn" href="/">WEDDING BANDS</div>
                        </div>
                    </a>
                </div>
                <div className="home-card-outter   col-sm-6 col-md-6 col-lg-3">
                    <a className="home-card  overflow-hidden  card text-white p-0" href='/our-fine-jewelry'>
                        <img src="assets/img/cat-img/fine-jewellery.png" className="img-fluid" alt="..." />
                        <div className="card-img-overlay btn-text">
                            <div className="btn cate-btn" href="/our-fine-jewelry">FINE JEWELLERY</div>
                        </div>
                    </a>
                </div>
                <div className="home-card-outter  col-sm-6 col-md-6 col-lg-3">
                    <a className="home-card  overflow-hidden  card text-white p-0" href='/fine-jewellery/param=20'>
                        <img src="assets/img/cat-img/customize.png" className="img-fluid" alt="..." />
                        <div className="card-img-overlay btn-text">
                            <div className="btn cate-btn" href="/fine-jewellery/type/Bracelets">WEDDING BANGLES</div>
                        </div>
                    </a>
                </div>                                              
            </div>
        </div>
          <Controller>
        <section className="create-ring-sec">
            <div className="container">
                <div className="row">
                    {/* <div className="col-md-6 create-ring-bg">
                        <img className="img-fluid" src="assets/img/diamond-1.png" alt="" />
                    </div> */}
                    <div className="col-lg-6  position-relative">
                     {/*   <div className="diamond_j"></div>
                        <div className="diamond_b">
                            <img alt="" className="img-fluid" src="/assets/img/ring.png" />
                        </div>*/}

                        <div className='ring-container' id='ring-container'>

                            <img src="/assets/img/ring.png" className='ring-img' alt='ring'/>

                            <Scene
                                triggerElement="#ring-container"
                                offset={-100}
                                duration={400}
                                indicators={false}
                            >
                                {(progress) => (
                                    <Tween
                                        from={{
                                            top: '-100px',
                                            backgroundPosition: "left top 0px",
                                            filter: "hue-rotate(0deg)",
                                        }}
                                        to={{
                                            top: '235px',
                                            backgroundPosition: "left top -5588px",
                                            filter: "hue-rotate(360deg)",
                                        }}
                                        ease='SteppedEase(19)'
                                        totalProgress={progress}
                                        paused
                                    >
                                        <div className='diamond_sprint'></div>
                                    </Tween>
                                )}

                            </Scene>


                            <img src="/assets/img/d-prong.png" className='prong_img' alt='ring-prong'/>
                        </div>
                    </div>
                    <div className="col-lg-6 engagement-ring-create align-self-center">
                        <h6><i>Choose your Own</i></h6>
                        <h1>CUSTOM-MADE ENGAGEMENT RING</h1>
                        <p><br/>Browse through our large collections of loose diamonds and matched it with your perfect setting. There’s no limit!<br/><br/></p>
                        <div className="ring-create-btn my-3 d-flex justify-content-start">
                            <Link to="/settings-diamond/all" className="btn btn-default-dark me-sm-2 mb-2 mb-sm-0">Start With a Diamond</Link>
               {/*         <p>Or</p>*/}
                            <Link to="/settings-ring/all" className="btn btn-default-dark">Start With a Setting</Link>
                        </div>
                        <p className='p2'>
                            <em>Don’t worry if you do not know where to start, reach out to our team for assistance!</em>
                        </p>
                    </div>
                </div>
            </div>
        </section>
          </Controller>
        <section className="custom-offer-sec my-5">
           
            <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="sectionTitle">

                        <h3>Your Personal Connoisseur Awaits</h3>
                        <p>Momentize your journey in our Experience Center</p>
                    </div>
                </div>
                <div className="col-md-6 custom-offer-benner" data-aos="fade-right">
                    <AnimatedOnScroll animationIn="bounceInLeft" >
                        <a href="https://calendly.com/baemont/colonyklcc" target="_blank" className="card text-white">
                            <img  src="assets/img/lumpur.png" className="card-img img-fluid" alt="..." />
                            <div className="card-img-overlay me-3">
                            <h5 className="card-title custom-offer-title">Colony Space KLCC,<br/>
                                Kuala Lumpur</h5>
                            <p className="card-text custom-offer-sub-title">Book KL Slot</p>
                            
                            </div>                       
                        </a>
                    </AnimatedOnScroll>
                </div>
                <div className="col-md-6 custom-offer-benner" data-aos="fade-left">
                    <AnimatedOnScroll animationIn="bounceInRight" >
                        <a href="https://calendly.com/baemont/no18capitolsingapore" target="_blank" className="card text-white">
                            <img src="assets/img/singapore.png" className="card-img img-fluid" alt="..." />
                            <div className="card-img-overlay me-3">
                            <h5 className="card-title custom-offer-title">No.18, Capitol Piazza,<br/>
                                Singapore</h5>
                            <p className="card-text custom-offer-sub-title">Book SG Slot</p>
                            
                            </div>
                        </a>
                    </AnimatedOnScroll>
                </div>
            </div>
            </div>
        </section>
          {/*<section className="custom-offer-sec my-5">
            <div className="container">
            <div className="row">
                <div className="col-lg-6 custom-offer-benner" data-aos="fade-right">
                    <AnimatedOnScroll animationIn="bounceInLeft" >
                        <div className="card text-white">
                            <img  src="assets/img/offer-banner-1.jpg" className="card-img img-fluid" alt="..." />
                            <div className="card-img-overlay me-3">
                            <h5 className="card-title custom-offer-title">CHOOSE YOUR<br/>
                                OWN</h5>
                            <p className="card-text custom-offer-sub-title">CUSTOM- MADE ENGAGEMENT RING</p>

                            </div>
                        </div>
                    </AnimatedOnScroll>
                </div>
                <div className="col-lg-6 custom-offer-benner" data-aos="fade-left">
                    <AnimatedOnScroll animationIn="bounceInRight" >
                        <div className="card text-white">
                            <img src="assets/img/offer-banner-2.jpg" className="card-img img-fluid" alt="..." />
                            <div className="card-img-overlay me-3">
                            <h5 className="card-title custom-offer-title">YOUR IDEAL<br/>
                                EVERYTHING</h5>
                            <p className="card-text custom-offer-sub-title">MOMENTISE YOURSELF WITH<br/>
                                OUR BEST COLLECTIONS</p>

                            </div>
                        </div>
                    </AnimatedOnScroll>
                </div>
            </div>
            </div>
        </section>*/}

        <section id="shopRings">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="sectionTitle">
                            <h5>Your Pick</h5>
                            <h3>Your Ideal Everything</h3>
                            <p>Momenlise yourself with our best collections</p>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="d-carousel-cener owl-carousel">
 

                {ring_collections && ring_collections.map((ring_item,index) => (
                    <div className="dc-inner" key={index}>
                        <a href={ring_item.href}>
                            <img alt="ring" src={"/assets/img/ring-collection/"+ring_item.image} />
                            <div className="dc-containt">
                                <h2>{ring_item.name}</h2>
                                <p>{ring_item.desc}</p>
                            </div>
                        </a>
                    </div>



                ))}



            </div>
        </section>


        <section className="rush-sec my-5">
            <div className="container">
            <div className="row">
                <div className="col-md-12 col-lg-6 no-rush-img">
                {/*<AnimatedOnScroll animationIn="bounceInLeft">*/}
                {/*    /!* <img className="img-fluid" src="assets/img/diamond-2.jpg" alt="" /> *!/*/}
                {/*    <iframe className='home-360' style={View3d} src="https://diamanti.s3.amazonaws.com/video/Vision360.html?d=220344-40" width="100%" frameBorder="0"></iframe>*/}
                {/*</AnimatedOnScroll>*/}
                    <video autoPlay muted loop className='w-100' playsInline controlsList="nodownload">
                        <source src="/assets/baemont_think_diamond_think_baemont.mp4" type="video/mp4"/>
                    </video>

                    {/*
                    <video src="/assets/Baemont – Think Diamond, Think BAEMONT!.mp4" autoPlay loop
                           controls playsInline controlsList="nodownload"></video>*/}

                </div>
                <div className="col-md-12 col-lg-6 no-rush align-self-center">
                <h5>No Rush, No Fuss</h5>
                <h2>Detailed Diamond Viewing</h2>

                        <p>Picking that special stone for that special someone requires time.<br/>It requires chemistry.
                            It requires that andrenaline rush to know <strong>‘That’s the One!’</strong><br/>At BAEMONT,
                                your time is of your essence.<br/>Simmer yourself with the sparkles of each diamond.<br/>No
                                    squinting of eyes. No rush for sales. No promotion that is running away.</p><p>Just
                        your time, your favourite drink and your decision!<br/><br/></p>
                <a className="btn" href="/settings-diamond/all">EXPERIENCE IT NOW</a>
                </div>
            </div>
            </div>
        </section>

        {/* <section className="detail-dimond-bg my-5">
            <div className="container">
                <div className="row">
                <div className="col-md-6 detail-dimond">
                    <h5>NO RUSH, NO FUSS</h5>
                    <h2>DETAILED DIAMOND VIEWING</h2>
                    <p>Picking that special stone for that special someone
                    requires time.
                    It requires chemistry. It requires that andrenaline
                    rush to know 'That's the One!’
                    
                    At BAEMONT, your time is of your essence.
                    Simmer yourself with the sparkles of each diamond.
                    NO squinting of eyes. No rush for sales. No promo-
                    tion that is running away.
                    
                    Just your time, your favourite drink and your decision!
                    </p>
                    <a className="btn" href="#">EXPERIENCE IT NOW</a>
                </div>
                <div className="col-md-6">
                    <AnimatedOnScroll animationIn="bounceInRight">
                        <img src="assets/img/detail-dimond-img.jpg" alt="" className="img-fluid" />
                    </AnimatedOnScroll>
                </div>
                </div>
            </div>
        </section> */}

        <section className='shopweddingbands' id="shopRings">
        <div className="sectionTitle">
            <h5>Tying it Altogether</h5>
            <h3>Wedding Bands</h3>
            <p>We believe what you believe too, the everlasting love</p>
        </div>

           
        <div className="d-carousel-cener owl-carousel">

            {wedingbands_collections && wedingbands_collections.map((weddingband_item,index) => (
                <div className="dc-inner" key={index}>
                    <a href={weddingband_item.href}>
                        <img alt="ring" src={"/assets/img/weddingband-collection/"+weddingband_item.image} />
                        <div className="dc-containt">
                            <h2>{weddingband_item.name}</h2>
                            <p>{weddingband_item.desc}</p>
                        </div>
                    </a>
                </div>



            ))}

            
           {/* <div className="dc-inner">
                <a href="single-product.html">
                <img alt="ring" src="/assets/img/shape-collection/1.png" />
                <div className="dc-containt">
                    <h2>Cushion</h2>
                    <p>Bar Set Anniversary Ring</p>
                </div>
                </a>
            </div>
            <div className="dc-inner">
                <a href="single-product.html">
                <img alt="ring" src="/assets/img/shape-collection/2.png" />
                <div className="dc-containt">
                    <h2>Round</h2>
                    <p>Bar Set Anniversary Ring</p>
                </div>
                </a>
            </div>
            <div className="dc-inner">
                <a href="single-product.html">
                <img alt="ring" src="/assets/img/shape-collection/3.png" />
                <div className="dc-containt">
                    <h2>Pear</h2>
                    <p>Bar Set Anniversary Ring</p>
                </div>
                </a>
            </div>
            <div className="dc-inner">
                <a href="single-product.html">
                <img alt="ring" src="/assets/img/shape-collection/4.png" />
                <div className="dc-containt">
                    <h2>Radiant</h2>
                    <p>Bar Set Anniversary Ring</p>
                </div>
                </a>
            </div>
            <div className="dc-inner">
                <a href="single-product.html">
                <img alt="ring" src="/assets/img/shape-collection/5.png" />
                <div className="dc-containt">
                    <h2>Ovel</h2>
                    <p>Bar Set Anniversary Ring</p>
                </div>
                </a>
            </div>*/}
        </div>
        </section>
          <section className="crafted-sec my-5">
              <div className="container">
                  <div className="row">

                      <div className="col-md-12 col-lg-6 no-rush align-self-center pb-5 pb-lg-0">
                          <h5>The Finest Craftmanship</h5>
                          <h2>Elegantly Designed and Carefully Crafted</h2>

                          <p>Every piece of jewellery is designed by our gifted designers and hand-crafted by our skilled artisans.</p>
                          <p>Our crafters are very passionate about the art of jewellery making, requiring their full attention to detail in producing the finest of quality.</p>
                          <a className="btn" href="/settings-ring/all">EXPERIENCE IT NOW</a>
                      </div>
                      <div className="col-md-12 col-lg-6 no-rush-img">
                             {/*<img className='img-fluid' src="/assets/img/detail-dimond-img.jpg"/>*/}
                          <video autoPlay loop muted className='w-100' playsInline controlsList="nodownload">
                              <source src="/assets/jewlry_making_joining_ring_trim.mp4" type="video/mp4"/>
                          </video>
                      </div>
                  </div>
              </div>
          </section>



       {/* <section className="crafted-design-bg my-5">
        <div className="container">
            <div className="row">
            <div className="col-md-12 col-lg-6 crafted-design-text">
                <h4>THE FINEST CRAFTMANSHIP<br/>
                Elegantly Designed and Carefully Crafted</h4>
                <p>Every piece of jewellery is designed by our gifted
                    designers and hand-crafted by our skilled artisans.
                </p>
                <p> Our crafters are very passionate about the art of
                    jewellery making, requiring their full attention to
                    detail in producing the finest of quality.</p>
                    
                    <a className="btn" href="/settings-diamond/all">EXPERIENCE IT NOW</a>

            </div>
            <div className="col-md-12 col-lg-6 crafted-design-img">

            </div>
            </div>
        </div>
        </section>*/}


        <section className="my-5">
        <div className="container">
            <div className="row g-3">
            <div className="sectionTitle">
                <h5>Connect with Us</h5>
                <h3>Join the #WITHBAE Movement</h3>
                <p>Follow us on Instagram @baemontrings and get inspired</p>
            </div>
            </div>
                {/*<div className="col-12 text-center">*/}
                {/*    <div className='instagram-feeds mt-3'>*/}
                {/*        {process.env.REACT_APP_Instagram_Access_Token}*/}
                {/*        <InstagramFeed token="hello"  counter="4"/>*/}
                {/*    </div>*/}
                {/*</div>*/}
            <div className="row">
            <div className="col-6 col-lg-3 mb-3 mb-lg-0">
                <a href='https://instagram.com/baemontrings' className="insta-card card text-white">
                <img src="assets/img/insta/1.jpg" className="card-img" alt="Instagram" />
                </a>
            </div>
                <div className="col-6 col-lg-3 mb-3 mb-lg-0">
                <a href='https://instagram.com/baemontrings' className="insta-card card text-white">
                <img src="assets/img/insta/2.jpg" className="card-img" alt="Instagram" />
                </a>
            </div>
                <div className="col-6 col-lg-3 mb-3 mb-lg-0">
                <a href='https://instagram.com/baemontrings' className="insta-card card text-white">
                <img src="assets/img/insta/3.jpg" className="card-img" alt="Instagram" />
                </a>
            </div>
                <div className="col-6 col-lg-3 mb-3 mb-lg-0">
                <a href='https://instagram.com/baemontrings' className="insta-card card text-white">
                <img src="assets/img/insta/4.jpg" className="card-img" alt="Instagram" />
                </a>
            </div>
            
            </div>
        </div>
        </section>

        {/*<section className="my-5">
        <div className="container">
            <div className="row">
            <div className="customer-logos slider">
            <div className="slide"><img src="assets/img/partner-1.jpg" /></div>
            <div className="slide"><img src="assets/img/partner-2.jpg" /></div>
            <div className="slide"><img src="assets/img/partner-3.jpg" /></div>
            <div className="slide"><img src="assets/img/partner-4.jpg" /></div>
            <div className="slide"><img src="assets/img/partner-5.jpg" /></div>
            <div className="slide"><img src="assets/img/partner-6.jpg" /></div>
            <div className="slide"><img src="assets/img/partner-7.jpg" /></div>
        </div>
            
            </div>
        </div>
        </section>*/}

      </>
    )
}

export default Home
